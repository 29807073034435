/**
 *  threeLayer.js
 *
 *  turf 는 공간분석 라이브러리
 *
 *  Geometry
 *  {@link https://threejs.org/docs/#api/en/geometries/SphereGeometry}
 *
 *  Material
 *  {@link https://threejs.org/docs/#api/en/materials/MeshPhongMaterial}
 *
 *  Mesh
 *  {@link https://threejs.org/docs/#api/en/objects/Mesh}
 * */

import code from "../common/code";
import * as turf from "@turf/turf";
import * as THREE from 'three';

export function addSpacePeople(lonlat, floor, color) {
  var baseLonlat = code.modelOrigin;
  var selectLonlat = lonlat;
  var fromX = turf.point([baseLonlat[0], baseLonlat[1]]);
  var toX = turf.point([selectLonlat[0], baseLonlat[1]]);
  var options = { units: 'meters' };
  var distanceX = turf.distance(fromX, toX, options);

  var fromY = turf.point([baseLonlat[0], baseLonlat[1]]);
  var toY = turf.point([baseLonlat[0], selectLonlat[1]]);
  var distanceY = turf.distance(fromY, toY, options);

  baseLonlat[0] - selectLonlat[0] < 0 ? distanceX : distanceX *= -1;
  baseLonlat[1] - selectLonlat[1] < 0 ? distanceY *= -1 : distanceY;

  const bodyRadiusTop = .7;

  const headRadius = bodyRadiusTop * 0.8;
  const headLonSegments = 24;
  const headLatSegments = 10;
  const headGeometry = new THREE.SphereGeometry(
      headRadius, headLonSegments, headLatSegments);
  const bodyMaterial = new THREE.MeshPhongMaterial({
      color: color
  });

  const head = new THREE.Mesh(headGeometry, bodyMaterial);
  if(selectedModel === 'kict98') {
    distanceY = distanceY -12.5;
  }
  else if(selectedModel === 'kict99') {
    distanceY = distanceY - 22.5;
  }
  head.position.x = distanceX;
  head.position.z = distanceY;
  return head;
}